import Swiper, {
  FreeMode,
  Mousewheel,
  Navigation,
  Autoplay,
  EffectFade,
} from "swiper";

export function home() {
  const home = $(".home");
  const btnStart = $("#btn-start");
  const btnGenerate = $("#btn-generate");
  const btnRestart = $("#btn-restart");
  const btnShare = $("#btn-share");
  const btnPopUpClose = $(".popup__close, .popup__overlay");
  const btnCopyLink = $("#btn-copy");
  const audioBackground = document.getElementById("audio-background");
  const btnRandomChoice = $("#btn-random-choice");

  let url;
  let imageId;
  let isPlay = false;

  document.body.addEventListener("click", function () {
    if (!isPlay) {
      isPlay = true;
      audioBackground.play();
      audioBackground.volume = 0.6;
    }
  });

  // Init Swiper
  let swiperChoice = new Swiper(".choice__swiper", {
    modules: [FreeMode, Mousewheel, Navigation],
    direction: "vertical",
    slidesPerView: 7,
    spaceBetween: 0,
    loop: true,
    centeredSlides: true,
    //cssMode: true,
    mousewheel: true,
    freeMode: {
      enabled: true,
      sticky: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      afterInit: function () {
        $('.swiper-slide[data-swiper-slide-index="0"]')
          .prev()
          .prev()
          .addClass("swiper-slide-prev-prev");
        $('.swiper-slide[data-swiper-slide-index="0"]')
          .next()
          .next()
          .addClass("swiper-slide-next-next");
      },
      slideChange: function () {
        var activeIndex = this.activeIndex;
        var realIndex = this.slides
          .eq(activeIndex)
          .attr("data-swiper-slide-index");
        $(".swiper-slide").removeClass(
          "swiper-slide-prev-prev swiper-slide-next-next"
        );
        $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]')
          .prev()
          .prev()
          .addClass("swiper-slide-prev-prev");
        $('.swiper-slide[data-swiper-slide-index="' + realIndex + '"]')
          .next()
          .next()
          .addClass("swiper-slide-next-next");

        //audioWheel.play();
      },
    },
  });

  // 01 - Start
  btnStart.on("click", function () {
    changeState("choice");
    setTimeout(function () {
      $(".choice__content").addClass("choice__content--show");
    }, 1000);
  });

  btnRandomChoice.on("click", function () {
    swiperChoice.slideToLoop(randomNumber(3, 20), 0, true);
    //console.log("btn random");
    changeState("generate");
    generateImages();
  });

  // 02 - Generate
  btnGenerate.on("click", function () {
    $(".choice__content").removeClass("choice__content--show");
    changeState("generate");
    generateImages();
  });

  function generateImages() {
    //console.log("generateImage")
    const themeSelected = $(".swiper-slide-active")
      .find(".choice__info")
      .attr("data-theme-id");
    // const themeSelected = 20;
    //console.log(themeSelected);

    const ajaxurl = btnGenerate.attr("data-ajaxurl");
    const data = {
      action: btnGenerate.attr("data-action"),
      nonce: btnGenerate.attr("data-nonce"),
      themeid: themeSelected,
    };

    fetch(ajaxurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
      },
      body: new URLSearchParams(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.success) {
          alert(response.data);
          return;
        }
        $(".generate__swiper").html(response.data);
        // Between 3 and 3,5 sec
        const interval = randomSecInterval(3000, 3500);
        // Generate Swiper
        const swiperGenerate = new Swiper(".generate__swiper", {
          modules: [Autoplay, EffectFade],
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          autoplay: {
            delay: 100,
          },
          speed: 0,
          allowTouchMove: false,
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
          on: {
            afterInit: function () {
             // console.log("interval : " + interval);
              // Slowdown
              setTimeout(function () {
                changeAutoPlay(swiperGenerate, 500);
                //console.log("0");
              }, interval - 2000);
              setTimeout(function () {
                changeAutoPlay(swiperGenerate, 300);
                //console.log("1");
              }, interval - 1500);
              setTimeout(function () {
                changeAutoPlay(swiperGenerate, 200);
               // console.log("2");
              }, interval - 1000);
              //Swiper stop / Show generated image
              setTimeout(function () {
                //console.log("3");
                swiperGenerate.autoplay.stop();
                replaceLinkUrl();
                $(".swiper-slide-active").addClass("show-ia");
              }, interval);
              // Show Share btn
              setTimeout(function () {
                $(".swiper-slide-active").addClass("show-share");
                $(".generate__buttons").addClass("generate__buttons--show");
              }, interval + 4000);
            },
          },
        });
      });
    }
  
  
    // 03 - Share
  btnShare.on("click", function () {
    $(".popup").addClass("popup--active");
  });

  btnCopyLink.on("click", function () {
    copyLink(url);
    $(".copied").fadeIn().delay(1000).fadeOut();
  });

  btnPopUpClose.on("click", function () {
    $(".popup").removeClass("popup--active");
  });

  // 04 - Restart
  btnRestart.on("click", function () {
    for (let i = 0; i <= randomNumber(3, 10); i++) {
      swiperChoice.slideNext(0, true);
    }
    $(".generate__content").addClass("generate__content--hide");
    setTimeout(function () {
      changeState("choice");
      setTimeout(function () {
        $(".choice__content").addClass("choice__content--show");
      }, 500);
      $(".generate__swiper").html("");
      $(".swiper-slide-active").removeClass("show-ia, show-share");
      $(".generate__buttons").removeClass("generate__buttons--show");
      $(".generate__content").removeClass("generate__content--hide");
    }, 1000);
  });

  /** Change State */
  function changeState(state) {
    home.attr("data-state", state);
  }

  /** Change swiper speed */
  function changeAutoPlay(swiper, milliseconds) {
    swiper.params.autoplay.delay = milliseconds;
  }
  /** Get random secondes */
  function randomSecInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /** Get random number */
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /** Copy Link */
  function copyLink(val) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  /** Replace Link URL */
  function replaceLinkUrl() {
    url = $(".swiper-slide-active").find(".generate__image--share").attr("src");
    imageId = $(".swiper-slide-active").find(".generate__image--share").data("id");
    url = url.replace("wp-content/uploads", "storage");
    $("#btn-save a").attr("href", url);

    $("#btn-facebook a").attr(
      "href",
      "https://www.facebook.com/sharer/sharer.php?u=https://faitesvosvoeux.salti.fr/?attachment_id=" +
      imageId +
        "&t=Salti"
    );
    $("#btn-x a").attr(
      "href",
      "https://twitter.com/intent/tweet?url=" + encodeURIComponent(url)
    );
    $("#btn-linkedin a").attr(
      "href",
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
        encodeURIComponent(url)
    );
  }

  //
  function setVolume(vol, decrease = true) {
    var interval = 200; // 200ms interval
    if (decrease == true) {
      var fadeout = setInterval(function () {
        // Reduce volume by 0.05 as long as it is above 0
        // This works as long as you start with a multiple of 0.05!
        if (vol > 0) {
          vol -= 0.05;
          audio.setVolume(vol);
        } else {
          clearInterval(fadeout);
        }
      }, interval);
    } else {
      var fadein = setInterval(function () {
        // Increase volume by 0.05 as long as it is above 0
        // This works as long as you start with a multiple of 0.05!
        if (vol <= 1) {
          vol += 0.05;
          audio.setVolume(vol);
        } else {
          clearInterval(fadein);
        }
      }, interval);
    }
  }
}
